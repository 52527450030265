<script setup>
import { onMounted } from 'vue';

onMounted(() => {
    document.querySelector('.header-img').scrollIntoView()
    document.querySelector('.logo').scrollIntoView()
})
</script>


<template>
    <img src="@/assets/events-header.jpg" alt="" class="header-img">

    <p class="section-title">EVENTS</p>
    <div class="line"></div>

    <div class="flex">

        <div class="text-box">
            <p class="text">
                Sie möchten einen Abend alleine mit Freunden voller Anregungen und Entdeckungen in der Buchhandlung genießen?
                Dieses besondere Event bieten wir nach Absprache und nach Ladenschluss an. 
                Frau Rieger erwartet Sie mit einem Glas Sekt oder Selters. Anschließend können Sie sich mit Ihren Freunden austauschen, Bücher lesen, stöbern oder auch nur plaudern.
                Dauer und Ende erfolgt nach Absprache.
            </p>
        </div>

        <div class="events-box">
            <div class="events-header">
                <p class="header-text">EVENTS</p>
            </div>
            <!-- <div class="event1">
                <div class="top">
                    <p class="date">28.12.2024     |</p>
                    <p class="subtitle">ENDE</p>
                    <img class="image1" src="@/assets/Ende.jpg" alt="">
                    <p class="info">
                        Am Samstag 28. Dezember wollen wir uns von ihnen persönlich verabschieden. Ab Elf Uhr können sie gerne auf einen Glühwein, Punsch und Gebäck vorbei kommen.
                    </p>
                </div>
            </div> -->
            <div class="event2">
            <div class="top">
                <p class="date">28.12.2024    |</p>
                <p class="subtitle">ENDE</p>
            </div>
            <div class="img-text">
                <img class="image1" src="@/assets/Ende.jpg" alt="">
                <p class="info">
                    Am Samstag 28. Dezember wollen wir uns von ihnen persönlich verabschieden. Ab Elf Uhr können sie gerne auf einen Glühwein, Punsch und Gebäck vorbei kommen.
                </p>
            </div>
        </div>
        </div>
    </div>
</template>


<style scoped>



hr{
margin-top: 65px;
border-top: 1px solid gray;
margin-bottom: -1px;
}
header{
    display: flex;
}

.header-img{
    /* width: 213.6vh;
    height: 40vh;;
    object-fit: cover; */
    max-width: 100%;
    height: auto;
    width: auto\9; /* ie8 */
    width: 100%;
    /* margin-top: -0.55px; */
    /* z-index: -100; */
}



.section-title{
    padding-top: 50px;
    font-size: 50px;
    color: black;
}
.line{
    width: 80%;
    height: 2px;
    background-color: black;
    margin-left: 10%;
    margin-top: 50px;
}
.text-box{
    width: 65%;
    margin-top: 25px;
    margin-bottom: 80px;

}
.text{
    font-size: 30px;
    text-align: justify;
}





.flex{
    display: flex;
    /* display: block; */
    /* block for mobile */
    align-items: flex-start;
    width: 80%;
    margin-left: 10%;
    margin-top: 4.5%;
    
}

.events-box{
    width: 30%;
    border: 1px solid black;
    border-radius: 10px;
    margin-left: 10%;
    margin-top: 25px;
    margin-bottom: 100px;
}
.events-header{
    border: 0.5px solid black;
    background: rgb(12, 32, 72);
    height: 50px;
    text-align: center;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}

.header-text{
    text-align: center;
    color: white;
    font-size: 20px;
    margin-top: 7px;
    
}

.date{
    overflow: hidden;
}


.top{
    display: flex;
    align-items: flex-start;
    margin-left: 20px;
    margin-top: 20px;
}
.subtitle{
    color:rgb(255, 187, 0);
    margin-left: 5px;
}


.img-text{
    display: flex;
    align-items: flex-start;
    margin-left: 20px;
    margin-top: 20px;
}
.image1{
    width: 25%;
    height: 20%;
    scale: 1;
    border-radius: 5px;
}

.image2{
    width: 25%;
    height: 20%;
    scale: 1.2;
    border-radius: 10px;
}
.info{
    text-align: left;
    margin-top: -5px;
    margin-left: 15px;
    padding-right: 10px;
}


.event1{
    margin-bottom: 20px;
}
.event2{
    margin-bottom: 20px;
}






@media all and (max-width: 1500px){
    .text{
        font-size: 27px;
    }
}
@media all and (max-width: 1300px){
    .text{
        font-size: 25px;
    }
}
@media all and (max-width: 1000px){
    .text{
        font-size: 23px;
    }
}
@media all and (max-width: 800px){
    .text{
        font-size: 20px;

    }
    .section-title{
    font-size: 45px;
    }
}
@media all and (max-width: 1100px){
    .flex{
    display: block;
    align-items: flex-start;
    }
.events-box{
    width: 100%;
    border: 1px solid black;
    margin-left: 0%;
    margin-top: 100px;
    margin-bottom: 150px;

}
.text-box{
    width: 100%;
    padding-top: 25px;
    padding-left: 0%;
    margin-bottom: 0px;

}

}

@media all and (max-width: 700px){
    .text{
        font-size: 19px;

    }
    .section-title{
    font-size: 40px;
    }
}
@media all and (max-width: 600px){
    .text{
        font-size: 19px;
        margin-bottom: 20px;

    }
    .section-title{
    font-size: 40px;
    }
}
@media all and (max-width: 550px){

    .section-title{
    font-size: 37px;
    }    }

    @media all and (max-width: 500px){
    .text{
        font-size: 17px;
        margin-top: 10px;
        margin-bottom: 20px;

    }
    .section-title{
    font-size: 35px;
    }
    .header{
        font-size: 25px;
    }

}
@media all and (max-width: 460px){
    .text{
        font-size: 16px;
    }
    .section-title{
    font-size: 30px;
    }
    .header{
        font-size: 20px;
        margin-top: -25px;
        font-weight: 400;
        margin-bottom: 20px;
    }

}
@media all and (max-width: 400px){
    .text{
        font-size: 15px;


    }
    .section-title{
    font-size: 30px;
    }
    .location iframe{
    width: 100%;
    height: 300px;
}

}
@media all and (max-width: 352px){
    .text{
        font-size: 14px;
    }
    .section-title{
    font-size: 30px;
    }

}

</style>